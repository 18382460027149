import styled from 'styled-components';
import { Link } from 'gatsby';

export default styled(Link)`
  display: inline-block;
  max-width: 5rem;
  max-height: 5rem
  svg {
    font-size: 2.5rem;
    path {
      stroke: white;
    }
  }
`;
