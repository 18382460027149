import React from 'react';
import { GrTechnology } from 'react-icons/gr';
import LogoStyles from '../styles/LogoStyles';
import logo from '../images/coinslive.png.png';

function Logo() {
  return (
    <LogoStyles to="/">
      <div style={{overflow: "hidden"}}>
        <img src={logo} alt="coinslive" />
      </div>
    </LogoStyles>
  );
}

export default Logo;
