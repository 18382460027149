import React from 'react';
// import axios from 'axios';
import { CryptoCoinsStyles } from '../../styles/homePage/CryptoCoinsStyles';
import { SectionTitle } from '../typography/Title';
import ParagraphText from '../typography/ParagraphText';
import Button from '../buttons/Button';
import { Link } from 'gatsby';
import { socialLinks } from '../../constants/socialLinks';

function CryptoCoins() {

  return (
    <CryptoCoinsStyles>
      <SectionTitle>NFT Market Place - Buy, Sell & Create NFTs</SectionTitle>
      <ParagraphText>
        Create NFTs for free on public network. <br></br> Follow the link!
      </ParagraphText>
      <Button to="/blogs" tag={Link} className="cryptoCoin__button">
        NFT MarketPlace!
      </Button>
      {/* <Button to="https://live-nft-marketplace-3.vercel.app/" tag={Link} className="cryptoCoin__button">
        NFT MarketPlace!
      </Button> */}
      <ul className="cryptoCoin__socialList">
        {socialLinks.map((item) => (
          <li key={item.name}>
            <a href={item.url}>{item.icon}</a>
          </li>
        ))}
      </ul>    
    </CryptoCoinsStyles>
  )
}

export default CryptoCoins