import React, { createContext, useState, useEffect } from 'react';
import { useLocalStorage } from '@mantine/hooks';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useLocalStorage({
    key: 'user',
    defaultValue: {}
  });

  const saveUser = (access, refresh = "") => {
    const user = {
      access: access,
      refresh: refresh
    }
    setUser(user);
  };

  const removeUser = () => {
    setUser({});
  };

  return (
    <AuthContext.Provider value={{ user, saveUser, removeUser }}>
      {children}
    </AuthContext.Provider>
  );
};
