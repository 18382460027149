export const menu = [
  {
    title: 'Home',
    path: '/',
  },
  {
    title: 'Blogs',
    path: '/blogs',
  },
  {
    title: 'Categories',
    path: '/categories',
  },
  // {
  //   title: 'DeX',
  //   path: '/trading-page',
  // },
  {
    title: 'NFT MarketPlace',
    path: '/blogs',
  },
  {
    title: 'Authors',
    path: '/authors',
  },
  {
    title: 'About Us',
    path: '/about-us',
  },
  {
    title: 'Terms Of Use',
    path: '/terms-of-use',
  },
  {
    title: 'Privacy Policy',
    path: '/privacy-policy',
  },
  {
    title: 'Disclaimer',
    path: '/disclaimer',
  },
];