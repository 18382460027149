// fonts
import '@fontsource/poppins/500.css';
import '@fontsource/poppins/700.css';
import '@fontsource/poppins/600.css';
import '@fontsource/inter/500.css';

import React from 'react';
// import Layout from './src/components/Layout';
// import { TradingPage } from './src/styles/TradingPage';
// import TradingPage2 from './src/components/trading-page';
import { AuthProvider } from './src/contexts/authContext';
import PrivateRoute from "./src/components/PrivateRoute";
import Layout from "./src/components/Layout";

// export const wrapPageElement = ({ element, props }) => {
//   console.log(element);
//   console.log(props);
//   console.log("yesss");
//   if(props.path == "/trading-page") return <TradingPage><TradingPage2 /></TradingPage>
//   else return <AuthProvider><Layout {...props}>{element}</Layout></AuthProvider>
//   // return (
//   //   {(props == "/trading-page") ? <div>Hhhh</div>
//   //   : <Layout {...props}>{element}</Layout>}
//   // )
// };

export const wrapRootElement = ({ element }) => (
  <AuthProvider>{element}</AuthProvider>
);

export const wrapPageElement = ({ element, props }) => {
  const { pageContext } = props;
  return pageContext.isPrivate ? (
    <PrivateRoute>
      <Layout {...props}>{element}</Layout>
    </PrivateRoute>
  ) : (
    <Layout {...props}>{element}</Layout>
  );
};
