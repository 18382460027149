import React, { useContext, useEffect } from 'react';
import { navigate } from 'gatsby';
import { AuthContext } from "../contexts/authContext";

const PrivateRoute = ({ children }) => {
  const { user } = useContext(AuthContext);

  useEffect(() => {
    if (!user || !user.access) {
      navigate('/login');
    }
  }, [user]);

  return user && user.access ? children : null;
};

export default PrivateRoute;
