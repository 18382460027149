exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-about-us-js": () => import("./../../../src/templates/about-us.js" /* webpackChunkName: "component---src-templates-about-us-js" */),
  "component---src-templates-author-list-js": () => import("./../../../src/templates/author-list.js" /* webpackChunkName: "component---src-templates-author-list-js" */),
  "component---src-templates-blog-list-js": () => import("./../../../src/templates/blog-list.js" /* webpackChunkName: "component---src-templates-blog-list-js" */),
  "component---src-templates-category-list-js": () => import("./../../../src/templates/category-list.js" /* webpackChunkName: "component---src-templates-category-list-js" */),
  "component---src-templates-disclaimer-js": () => import("./../../../src/templates/disclaimer.js" /* webpackChunkName: "component---src-templates-disclaimer-js" */),
  "component---src-templates-login-js": () => import("./../../../src/templates/login.js" /* webpackChunkName: "component---src-templates-login-js" */),
  "component---src-templates-privacy-policy-js": () => import("./../../../src/templates/privacy-policy.js" /* webpackChunkName: "component---src-templates-privacy-policy-js" */),
  "component---src-templates-single-author-js": () => import("./../../../src/templates/single-author.js" /* webpackChunkName: "component---src-templates-single-author-js" */),
  "component---src-templates-single-blog-js": () => import("./../../../src/templates/single-blog.js" /* webpackChunkName: "component---src-templates-single-blog-js" */),
  "component---src-templates-single-category-js": () => import("./../../../src/templates/single-category.js" /* webpackChunkName: "component---src-templates-single-category-js" */),
  "component---src-templates-terms-of-use-js": () => import("./../../../src/templates/terms-of-use.js" /* webpackChunkName: "component---src-templates-terms-of-use-js" */)
}

